/** @format */

export default [
  {
    name: 'Earnings_record',
    path: '/Earnings/record',
    component: () => import('@/views/vip_web/EarningsRecord/OrderList'),
    meta: {
      pageTitle: 'Earnings Record',
      breadcrumb: [
        {
          text: 'Earnings Record',
        },
      ],
    },
  },
  {
    path: '/vip/home',
    name: 'vip_home',
    component: () => import('@/views/vip_web/dashboard'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Index',
        },
      ],
    },
  },
  {
    path: '/vip/userinfo',
    name: 'vip_info',
    component: () => import('@/views/vip_web/Info'),
    meta: {
      pageTitle: 'My Account Info',
      breadcrumb: [
        {
          text: 'My Account Info',
        },
      ],
    },
  },
  {
    path: '/vip/next-per',
    name: 'vip_next',
    component: () => import('@/views/vip_web/Info/next_person'),
    meta: {
      pageTitle: 'Downline Agent',
      breadcrumb: [
        {
          text: 'Downline Agent',
        },
      ],
    },
  },
  {
    path: '/vip/team',
    name: 'team_list',
    component: () => import('@/views/vip_web/team/list'),
    meta: {
      pageTitle: 'Team list',
      breadcrumb: [
        {
          text: 'Team list',
        },
      ],
    },
  },
  {
    path: '/vip/insurance-policy',
    name: 'vip_insurance-policy',
    component: () => import('@/views/vip_web/Order/index.vue'),
    meta: {
      pageTitle: 'Policy List',
      breadcrumb: [
        {
          text: 'Policy List',
        },
      ],
    },
  },
  // {
  //   path: '/vip/commission_record',
  //   name: 'vip_Commission_record',
  //   component: () => import('@/views/vip_web/Order/commission/OrderList'),
  //   meta: {
  //     pageTitle: '保单记录',
  //     breadcrumb: [
  //       {
  //         text: '保单记录',
  //       },
  //     ],
  //   },
  // },
  {
    path: '/vip_pay_record',
    name: 'vip_Pay_record',
    component: () => import('@/views/vip_web/Order/pay/OrderList'),
    meta: {
      pageTitle: 'Payment records',
      breadcrumb: [
        {
          text: 'Payment records',
        },
      ],
    },
  },
  {
    path: '/vip/insurance-policy/create',
    name: 'vip_insurance-policy_create',
    component: () => import('@/views/vip_web/Order/OrderList/OrderCreate.vue'),
    meta: {
      pageTitle: 'Policy add',
      breadcrumb: [
        {
          text: 'Policy List',
          to: '/vip/insurance-policy',
        },
        {
          text: 'Policy add',
        },
      ],
    },
  },
  {
    path: '/vip/insurance-policy/edit',
    name: 'vip_insurance-policy_edit',
    component: () => import('@/views/vip_web/Order/OrderList/OrderEdit.vue'),
    meta: {
      pageTitle: 'Policy changes',
      breadcrumb: [
        {
          text: 'Policy List',
          to: '/vip/insurance-policy',
        },
        {
          text: 'Policy changes',
        },
      ],
    },
  },

  {
    path: '/vip/insurance-policy-submit',
    name: 'vip_insurance-policy-submit',
    component: () => import('@/views/vip_web/Order/OrderList/OrderCreate.vue'),
    meta: {
      pageTitle: 'Policy submit',
      breadcrumb: [
        {
          text: 'Policy List',
          to: '/vip/insurance-policy',
        },
        {
          text: 'Policy submit',
        },
      ],
    },
  },
  {
    path: '/vip/insurance-policy-detail',
    name: 'vip_insurance-policy-detail',
    component: () => import('@/views/vip_web/Order/OrderList/OrderDetail.vue'),
    meta: {
      pageTitle: 'Policy details',
      breadcrumb: [
        {
          text: 'Policy List',
          to: '/vip/insurance-policy',
        },
        {
          text: 'Policy details',
        },
      ],
    },
  },

  {
    path: '/vip/tutorial-use',
    name: 'vip_tutorial-use',
    component: () => import('@/views/vip_web/Help/help'),
    meta: {
      pageTitle: 'Using the tutorial',
      breadcrumb: [
        {
          text: 'Using the tutorial',
        },
      ],
    },
  },
  {
    path: '/vip/tutorial-video',
    name: 'vip_tutorial-video',
    component: () => import('@/views/vip_web/Help/training-video'),
    meta: {
      pageTitle: '2025 Gala',
      breadcrumb: [
        {
          text: '2025 Gala',
        },
      ],
    },
  },
  {
    path: '/vip/tutorial-material',
    name: 'vip_tutorial-material',
    component: () => import('@/views/vip_web/Help/training-materials'),
    meta: {
      pageTitle: 'Training materials',
      breadcrumb: [
        {
          text: 'Training materials',
        },
      ],
    },
  },
  {
    path: '/vip/tutorial-library',
    name: 'vip_tutorial-library',
    component: () => import('@/views/vip_web/Library/Faq'),
    meta: {
      pageTitle: 'Article library',
      breadcrumb: [
        {
          text: 'Article library',
        },
      ],
    },
  },
  {
    path: '/vip/tutorial-library-brochures',
    name: 'vip_tutorial-library-brochures',
    component: () => import('@/views/vip_web/Library2/Faq'),
    meta: {
      pageTitle: 'Brochures',
      breadcrumb: [
        {
          text: 'Brochures',
        },
      ],
    },
  },
  {
    path: '/vip/tutorial-library-training&tutorial',
    name: 'vip_tutorial-library-training&tutorial',
    component: () => import('@/views/vip_web/Library3/Faq'),
    meta: {
      pageTitle: 'Training & Tutorial',
      breadcrumb: [
        {
          text: 'Training & Tutorial',
        },
      ],
    },
  },
  {
    path: '/vip/tutorial-library-compliance',
    name: 'vip_tutorial-library-compliance',
    component: () => import('@/views/vip_web/Library4/Faq'),
    meta: {
      pageTitle: 'Compliance Manual',
      breadcrumb: [
        {
          text: 'Compliance Manual',
        },
      ],
    },
  },
  {
    path: '/vip/tutorial-library-detail',
    name: 'vip_tutorial-library-detail',
    component: () => import('@/views/vip_web/Library/FaqDetail.vue'),
    meta: {
      pageTitle: 'Article library detail',
      breadcrumb: [
        {
          to: '/vip/tutorial-library',
          text: 'Article library',
        },
        {
          text: 'Article library detail',
        },
      ],
    },
  },

  {
    path: '/vip/tutorial-library-brochures-detail',
    name: 'vip_tutorial-library-brochures-detail',
    component: () => import('@/views/vip_web/Library/FaqDetail.vue'),
    meta: {
      pageTitle: 'Brochures detail',
      breadcrumb: [
        {
          to: '/vip/tutorial-library-brochures',
          text: 'Brochures',
        },
        {
          text: 'Brochures detail',
        },
      ],
    },
  },

  {
    path: '/vip/tutorial-library-training&tutorial-detail',
    name: 'vip_tutorial-library-training&tutorial-detail',
    component: () => import('@/views/vip_web/Library3/FaqDetail.vue'),
    meta: {
      pageTitle: 'Training & Tutorial detail',
      breadcrumb: [
        {
          to: '/vip/tutorial-library-training&tutorial',
          text: 'Training & Tutorial',
        },
        {
          text: 'Training & Tutorial detail',
        },
      ],
    },
  },

  {
    path: '/vip/tutorial-library-compliance-detail',
    name: 'vip_tutorial-library-compliance-detail',
    component: () => import('@/views/vip_web/Library/FaqDetail.vue'),
    meta: {
      pageTitle: 'Compliance Manual detail',
      breadcrumb: [
        {
          to: '/vip/tutorial-library-compliance',
          text: 'Compliance Manual',
        },
        {
          text: 'Compliance Manual detail',
        },
      ],
    },
  },

  {
    path: '/vip/analytics',
    name: 'vip_analytics',
    component: () => import('@/views/vip_web/Report'),
    meta: {
      pageTitle: 'TP report',
      breadcrumb: [
        {
          text: 'TP report',
        },
      ],
    },
  },
  {
    path: '/vip/earning_records',
    name: 'vip_earning_records',
    component: () => import('@/views/vip_web/Report'),
    meta: {
      pageTitle: 'Earning Records',
      breadcrumb: [
        {
          text: 'Earning Recordst',
        },
      ],
    },
  },

  {
    path: '/vip/commission-report',
    name: 'vip_commission-report',
    component: () => import('@/views/vip_web/Report/commission-report'),
    meta: {
      pageTitle: 'Commission report',
      breadcrumb: [
        {
          text: 'Commission report',
        },
      ],
    },
  },
  {
    path: '/vip/score-report',
    name: 'vip_score-report',
    component: () => import('@/views/vip_web/Report/score_10_19'),
    meta: {
      pageTitle: 'Scoreboards',
      breadcrumb: [
        {
          text: 'Scoreboards',
        },
      ],
    },
  },
  {
    path: '/vip/inforce-TP',
    name: 'vip_inforce_TP',
    component: () => import('@/views/vip_web/scoreboards/inforce_TP'),
    meta: {
      pageTitle: 'Inforce TP',
      breadcrumb: [
        {
          text: 'Inforce TP',
        },
      ],
    },
  },
  {
    path: '/vip/pending-TP',
    name: 'vip_pending_TP',
    component: () => import('@/views/vip_web/scoreboards/pending_TP'),
    meta: {
      pageTitle: 'Pending TP',
      breadcrumb: [
        {
          text: 'Pending TP',
        },
      ],
    },
  },
  {
    path: '/vip/recruiting',
    name: 'vip_recruiting',
    component: () => import('@/views/vip_web/scoreboards/recruiting'),
    meta: {
      pageTitle: 'Recruiting',
      breadcrumb: [
        {
          text: 'Recruiting',
        },
      ],
    },
  },
  {
    path: '/vip/licensing_appointment',
    name: 'vip_licensing_appointment',
    component: () => import('@/views/vip_web/licensing_appointment/list.vue'),
    meta: {
      pageTitle: 'How to Start?',
      breadcrumb: [
        {
          text: 'Licensing & Appointment',
        },
      ],
    },
  },
  {
    path: '/vip/newsList',
    name: 'vip_news-list',
    component: () => import('@/views/vip_web/News/index'),
    meta: {
      pageTitle: 'News',
      breadcrumb: [
        {
          text: 'News',
        },
      ],
    },
  },
  {
    path: '/calendar/index',
    name: 'calendar',
    component: () => import('@/views/calendar_vip/Calendar.vue'),
    meta: {
      pageTitle: 'Calendar',
      breadcrumb: [
        {
          text: 'Calendar',
        },
      ],
    },
  },

  {
    path: '/vip/messageList',
    name: 'vip_message-list',
    // component: () => import('@/views/email/Chat'),
    component: () => import('@/views/vip_web/LetterManager/index'),
    meta: {
      // pageTitle: '信件管理',
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      // breadcrumb: [
      //   {
      //     text: '信件查看',
      //   },
      // ],
    },
  },
  {
    name: 'team_reward',
    path: '/general/team_reward',
    component: () => import('@/views/vip_web/Order/OrderList/TeamReward'),
    meta: {
      pageTitle: 'Team rewards',
      breadcrumb: [
        {
          text: 'Team rewards',
        },
      ],
    },
  },
  {
    name: 'vip_Commission_record',
    path: '/vip/Commission_record',
    component: () => import('@/views/vip_web/Commission_record/OrderList.vue'),
    meta: {
      pageTitle: 'Commission Report',
      breadcrumb: [
        {
          text: 'Commission Report',
        },
      ],
    },
  },
  {
    name: 'vip_Commission_record_detail',
    path: '/vip/Commission_record_detail',
    component: () => import('@/views/vip_web/Commission_record/OrderDetail.vue'),
    meta: {
      pageTitle: 'Commission Report',
      breadcrumb: [
        {
          text: 'Commission Report',
          to: '/vip/Commission_record'
        },
      ],
    },
  },
  {
    name: 'level_detail',
    path: '/dashboard/level_info',
    component: () => import('@/views/vip_web/levelinfo.vue'),
    meta: {
      pageTitle: 'Renewal & Excess Grid',
      breadcrumb: [
        {
          text: 'Renewal & Excess Grid',
        },
      ],
    },
  },
  {
    name: 'level_detail_two',
    path: '/dashboard/level_info_two',
    component: () => import('@/views/vip_web/level_t.vue'),
    meta: {
      pageTitle: 'Renewal & Excess Grid',
      breadcrumb: [
        {
          text: 'Renewal & Excess Grid',
        },
      ],
    },
  },
  {
    name: 'peixun_detail',
    path: '/vip/peixun_detail',
    component: () => import('@/views/vip_web/Help/detail.vue'),
    meta: {
      pageTitle: 'Training details',
      breadcrumb: [
        {
          text: 'Training details',
        },
      ],
    },
  },
]
